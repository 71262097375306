const languagesList = {
  language: [
    { id: 1, label: 'Afrikaans - Afrikaans', value: 'Afrikaans' },
    { id: 2, label: 'العربية - Arabic', value: 'Arabic' },
    { id: 3, label: 'Հայերեն - Armenian', value: 'Armenian' },
    { id: 4, label: 'Azərbaycanca - Azerbaijani', value: 'Azerbaijani' },
    { id: 5, label: 'Беларуская - Belarusian', value: 'Belarusian' },
    { id: 6, label: 'Bosanski - Bosnian', value: 'Bosnian' },
    { id: 7, label: 'Български - Bulgarian', value: 'Bulgarian' },
    { id: 8, label: 'Català - Catalan', value: 'Catalan' },
    { id: 9, label: '中國人 - Chinese', value: 'Chinese' },
    { id: 10, label: 'Hrvatski - Croatian', value: 'Croatian' },
    { id: 11, label: 'Čeština - Czech', value: 'Czech' },
    { id: 12, label: 'Dansk - Danish', value: 'Danish' },
    { id: 13, label: 'Nederlands - Dutch', value: 'Dutch' },
    { id: 14, label: 'English - English', value: 'English' },
    { id: 15, label: 'Eesti - Estonian', value: 'Estonian' },
    { id: 16, label: 'Suomi - Finnish', value: 'Finnish' },
    { id: 17, label: 'Français - French', value: 'French' },
    { id: 18, label: 'Galego - Galician', value: 'Galician' },
    { id: 19, label: 'Deutsch - German', value: 'German' },
    { id: 20, label: 'Ελληνικά - Greek', value: 'Greek' },
    { id: 21, label: 'עברית - Hebrew', value: 'Hebrew' },
    { id: 22, label: 'हिन्दी - Hindi', value: 'Hindi' },
    { id: 23, label: 'Magyar - Hungarian', value: 'Hungarian' },
    { id: 24, label: 'Íslenska - Icelandic', value: 'Icelandic' },
    { id: 25, label: 'Italiano - Italian', value: 'Italian' },
    { id: 26, label: '日本語 - Japanese', value: 'Japanese' },
    { id: 27, label: 'ಕನ್ನಡ - Kannada', value: 'Kannada' },
    { id: 28, label: 'Қазақ - Kazakh', value: 'Kazakh' },
    { id: 29, label: '한국어 - Korean', value: 'Korean' },
    { id: 30, label: 'Македонски - Macedonian', value: 'Macedonian' },
    { id: 31, label: 'Bahasa Melayu - Malay', value: 'Malay' },
    { id: 32, label: 'Māori - Maori', value: 'Maori' },
    { id: 33, label: 'मराठी - Marathi', value: 'Marathi' },
    { id: 34, label: 'ਪੰਜਾਬੀ - Panjabi', value: 'Punjabi' },
    { id: 35, label: 'فارسی - Persian', value: 'Persian' },
    { id: 36, label: 'Polski - Polish', value: 'Polish' },
    { id: 37, label: 'Português - Portuguese', value: 'Portuguese' },
    { id: 38, label: 'Română - Romanian', value: 'Romanian' },
    { id: 39, label: 'Русский - Russian', value: 'Russian' },
    { id: 40, label: 'Српски - Serbian', value: 'Serbian' },
    { id: 41, label: 'Slovenčina - Slovak', value: 'Slovak' },
    { id: 42, label: 'Slovenščina - Slovene', value: 'Slovene' },
    { id: 43, label: 'Español - Spanish', value: 'Spanish' },
    { id: 44, label: 'Kiswahili - Swahili', value: 'Swahili' },
    { id: 45, label: 'Svenska - Swedish', value: 'Swedish' },
    { id: 46, label: 'தமிழ் - Tamil', value: 'Tamil' },
    { id: 47, label: 'ไทย - Thai', value: 'Thai' },
    { id: 48, label: 'Türkçe - Turkish', value: 'Turkish' },
    { id: 49, label: 'Українська - Ukrainian', value: 'Ukrainian' },
    { id: 50, label: 'اردو - Urdu', value: 'Urdu' },
    { id: 51, label: 'Tiếng Việt - Vietnamese', value: 'Vietnamese' },
    { id: 52, label: 'Cymraeg - Welsh', value: 'Welsh' },
  ],
};

export default languagesList;

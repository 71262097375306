import { IconArrowRight } from '@tabler/icons-react';
import axios, { AxiosError } from 'axios';
import Image from 'chat-ui/src/components/Image/Image';
import useTheme from 'chat-ui/src/hooks/useTheme';
import Cookies from 'js-cookie';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import getTextColor from 'chat-ui/src/hooks/getTextColorContrast';
import { EChatErrors, ECompanyThemeName } from '@/enums/enums';
import TelletLogo from '@/assets/tellet-logo-main-white.svg';
import EmpathyLogo from '@/assets/empaty-logo.svg';
import languagesList from '../languageList';
import SelectForm from '../SelectForm';
import {
  translateHi,
  translateInterviewLanguageOfChoice,
  translateLetsGo,
  translateAreYouReadyToShareYourOpinion,
  translations,
  translateInterviewTerms,
} from '../translatedConsentText';
import { Checkbox } from '../ui/checkbox';

interface IConsent {
  projectId: string | undefined;
  setProjectConsent: Dispatch<SetStateAction<boolean>>;
  themeData: ICreateThemeData;
}

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" height={50} width={50} className="absolute bottom-5 right-6" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" height={120} width={120} className="absolute bottom-5 right-6" />,
};

type LanguageCode = keyof typeof translations;

function ConsentScreen({ projectId, setProjectConsent, themeData }: IConsent) {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({ label: '', value: '' });
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState('');
  const { chat_id } = useParams();
  const navigate = useNavigate();
  const [showLanguage, setShowLanguage] = useState(false);
  const location = useLocation();
  const [browserLanguage, setBrowserLanguage] = useState('');
  const [searchParams] = useSearchParams();
  const isSandboxChat = searchParams.get('environment');
  const sandboxLink = isSandboxChat === 'sandbox';
  const { themeName } = useTheme();
  const translation = translations[browserLanguage as LanguageCode] || translations.en;

  const interviewTermsLink = themeName === ECompanyThemeName.EMPATHY
    ? 'https://ai-terms.empathyiq.ai/'
    : translation.interviewTermsLink;

  const handleSubmit = () => {
    localStorage.setItem(`project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}`, 'true');
    setProjectConsent(true);
  };

  const sanitizedFirstParagraph = DOMPurify.sanitize(
    translation.firstParagraph
      .replace('{interviewTerms}', `<a href="${interviewTermsLink}" target="_blank" class="font-medium underline">${translation.interviewTerms}</a>`)
      .replace('{privacyPolicy}', `<a href="${privacyPolicyLink}" target="_blank" class="font-medium underline">${translation.privacyPolicy}</a>`),
  );

  const fetchData = async () => {
    try {
      const res = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/projects/consent_screen/${chat_id}`);
      const { data } = res;

      const languageItem = languagesList.language.filter((item) => item.value === data?.language);
      Cookies.set('current_organization_chat', data?.organization_id);
      setPrivacyPolicyLink(data?.privacy_policy);
      setShowLanguage(data?.allow_language_selection);
      setSelectedLanguage(languageItem[0]);
    } catch (err) {
      setPrivacyPolicyLink('');
    }
  };

  useEffect(() => {
    const { language } = navigator;
    const formattedLanguage = new Intl.Locale(language).language;
    setBrowserLanguage(formattedLanguage);
  }, []);

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    try {
      if (sandboxLink) {
        const res = await axios.get(`${import.meta.env.VITE_CHAT_URL}/chat/sandbox/token/${chat_id}?language=${selectedLanguage.value}&${location.search.slice(1)}`);

        if (res?.data) {
          localStorage.setItem(`current_conversation_id/sandbox/${projectId}`, res?.data?.conversationId);
          localStorage.setItem(`current_project_token_/${chat_id}/sandbox`, res?.data?.token);
        }
      } else {
        const res = await axios.get(`${import.meta.env.VITE_CHAT_URL}/chat/token/${chat_id}?language=${selectedLanguage.value}&${location.search.slice(1)}`);
        localStorage.setItem(`current_project_token_/${chat_id}`, res?.data?.token);
      }
      handleSubmit();
    } catch (err) {
      const axiosError = err as AxiosError;
      const chatError = axiosError?.response?.status;
      if (chatError === EChatErrors.PROJECT_DO_NOT_EXIST) {
        return navigate(`/chat/error?error_status=${EChatErrors.PROJECT_DO_NOT_EXIST}`);
      }
      if (chatError === EChatErrors.PROJECT_IS_DRAFT_OR_READY) {
        return navigate(`/chat/error?error_status=${EChatErrors.PROJECT_IS_DRAFT_OR_READY}`);
      }
      if (chatError === EChatErrors.PROJECT_IS_COMPLETED) {
        return navigate(`/chat/error?error_status=${EChatErrors.PROJECT_IS_COMPLETED}`);
      }
      if (chatError === EChatErrors.MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED) {
        return navigate(`/chat/error?error_status=${EChatErrors.MAXIMUM_COUNT_OF_CONVERSATION_EXCEEDED}`);
      }

      navigate('/chat/error-page');
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSandboxChat !== null && isSandboxChat !== 'sandbox') {
      navigate('/chat/error-page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSandboxChat]);

  return (
    <div
      style={{
        backgroundColor: themeData?.['chat-consent-bg'] || '#FF6F98',
        color: getTextColor(themeData?.['chat-consent-bg'] || '#FFFFFF'),
      }}
      className="relative flex flex-col items-center justify-between w-full h-full min-h-[100dvh] px-8 pt-12 text-white"
    >
      {/* logo */}
      {themeData?.name === 'Default' && themeLogosList[themeName]}

      <div className="flex flex-col w-full h-full max-w-[430px]">
        <h2 className="mb-6 text-4xl font-bold">
          {translateHi[browserLanguage] || translateHi.en}
          !
        </h2>

        <p className="mb-3 text-base font-light">
          {translateAreYouReadyToShareYourOpinion[browserLanguage] || translateAreYouReadyToShareYourOpinion.en}
        </p>

        <p className="mb-3 text-base font-light" dangerouslySetInnerHTML={{ __html: sanitizedFirstParagraph }} />

        {/* choose language */}
        {showLanguage && selectedLanguage.value !== '' && (
          <div className="relative flex flex-col w-full gap-5 mt-10">
            <p className="text-base font-light">{translateInterviewLanguageOfChoice[browserLanguage] || translateInterviewLanguageOfChoice.en}</p>
            <SelectForm selectValue={selectedLanguage} selectOnChange={setSelectedLanguage} selectList={languagesList.language} />
          </div>
        )}
      </div>

      <div className="flex font-semibold flex-col w-full h-full max-w-[430px]">
        <div className="flex items-center self-end gap-4 mb-8">
          <p className="text-base font-light max-w-[190px]">
            {translateInterviewTerms[browserLanguage] || translateInterviewTerms.en}
          </p>
          <Checkbox
            onClick={() => setIsTermsChecked(!isTermsChecked)}
            checked={isTermsChecked}
            className="w-9 h-9 border-2 mt-2 border-white rounded-xl data-[state=checked]:bg-white data-[state=checked]:text-chat-primary"
          />
        </div>
        <button
          type="button"
          onClick={onSubmit}
          disabled={!isTermsChecked}
          style={{ backgroundColor: themeData?.primary, opacity: isTermsChecked ? 1 : 0.7 }}
          className="flex items-center self-end justify-center h-10 gap-1 px-3 mb-20 border-2 border-white w-fit rounded-xl hover:bg-green-primary-dark"
        >
          {translateLetsGo[browserLanguage] || translateInterviewLanguageOfChoice.en}
          <IconArrowRight size={20} />
        </button>
      </div>
    </div>
  );
}

export default ConsentScreen;

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Chat from '@/components/chat/Chat';
import ConsentScreen from '@/components/chat/ConsentScreen';
import FullPageLoader from '@/components/FullPageLoader';
import { useThemeQuery } from '@/reactQuery/get';

function ChatPage() {
  const projectId = window.location.pathname;
  if (projectId) projectId.replace('/', '');
  const [searchParams] = useSearchParams();
  const isSandboxChat = searchParams.get('environment');
  const sandboxLink = isSandboxChat === 'sandbox';
  const [projectConsent, setProjectConsent] = useState(false);
  const { data: themeData, isLoading: isThemeLoading } = useThemeQuery(projectId);

  useEffect(() => {
    const localStorageConsent = localStorage.getItem(`project_consent_${projectId}${sandboxLink ? '/sandbox' : ''}`);
    setProjectConsent(localStorageConsent === 'true');
  }, [projectId, sandboxLink]);

  if (isThemeLoading) return <FullPageLoader themeData={themeData} />;

  if (!projectConsent) {
    return (
      <ConsentScreen
        projectId={projectId}
        setProjectConsent={setProjectConsent}
        themeData={themeData}
      />
    );
  }

  return (
    <div
      className="min450:bg-white chat-page"
    >
      <div
        style={{ backgroundColor: themeData?.['chat-background'] || '#F3F4F6' }}
        className="fixed top-0 bottom-0 left-0 right-0 w-full h-full min500:!bg-white"
      >
      </div>
      <Chat
        chatHeight="h-[calc(100dvh-95px)] scrollbar-hidden min500:h-[calc(100dvh-85px)]"
        projectId={projectId}
        setProjectConsent={setProjectConsent}
      />
    </div>
  );
}

export default ChatPage;

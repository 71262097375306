interface Translation {
  [key: string]: string;
}

export const translations = {
  af: {
    firstParagraph: 'Voordat ons begin, kyk asseblief na ons {interviewTerms} en {privacyPolicy} om te sien hoe ons jou terugvoer gaan gebruik.',
    interviewTerms: 'Onderhoudsvoorwaardes',
    privacyPolicy: 'Privaatheidsbeleid',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ar: {
    firstParagraph: 'قبل أن نبدأ، يرجى مراجعة {interviewTerms} و{privacyPolicy} لترى كيف سنستخدم ملاحظاتك.',
    interviewTerms: 'شروط المقابلة',
    privacyPolicy: 'سياسة الخصوصية',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hy: {
    firstParagraph: 'Մինչ մենք սկսենք, խնդրում ենք ծանոթանալ մեր {interviewTerms} և {privacyPolicy}՝ տեսնելու, թե ինչպես ենք օգտագործելու ձեր արձագանքը:',
    interviewTerms: 'Հարցազրույցի պայմաններ',
    privacyPolicy: 'Գաղտնիության քաղաքականություն',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  az: {
    firstParagraph: 'Başlamazdan əvvəl, rəyinizi necə istifadə edəcəyimizi görmək üçün zəhmət olmasa {interviewTerms} və {privacyPolicy} yoxlayın.',
    interviewTerms: 'Müsahibə şərtləri',
    privacyPolicy: 'Məxfilik Siyasəti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  be: {
    firstParagraph: 'Перш чым мы пачнем, калі ласка, праверце нашы {interviewTerms} і {privacyPolicy}, каб убачыць, як мы будзем выкарыстоўваць вашыя водгукі.',
    interviewTerms: "Умовы інтэрв'ю",
    privacyPolicy: 'Палітыка прыватнасці',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  bs: {
    firstParagraph: 'Prije nego počnemo, molimo vas da provjerite naše {interviewTerms} i {privacyPolicy} kako biste vidjeli kako ćemo koristiti vaše povratne informacije.',
    interviewTerms: 'Uvjeti intervjua',
    privacyPolicy: 'Politika privatnosti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  bg: {
    firstParagraph: 'Преди да започнем, моля, прегледайте нашите {interviewTerms} и {privacyPolicy}, за да видите как ще използваме вашата обратна връзка.',
    interviewTerms: 'Условия за интервю',
    privacyPolicy: 'Политика за поверителност',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ca: {
    firstParagraph: 'Abans de començar, consulta els nostres {interviewTerms} i {privacyPolicy} per veure com utilitzarem els teus comentaris.',
    interviewTerms: "Termes de l'entrevista",
    privacyPolicy: 'Política de privacitat',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  zh: {
    firstParagraph: '在我们开始之前，请查看我们的{interviewTerms}和{privacyPolicy}，了解我们将如何使用您的反馈。',
    interviewTerms: '面试条款',
    privacyPolicy: '隐私政策',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hr: {
    firstParagraph: 'Prije nego što započnemo, molimo vas da pregledate naše {interviewTerms} i {privacyPolicy} kako biste vidjeli kako ćemo koristiti vaše povratne informacije.',
    interviewTerms: 'Uslovi intervjua',
    privacyPolicy: 'Politika privatnosti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  cs: {
    firstParagraph: 'Než začneme, podívejte se prosím na naše {interviewTerms} a {privacyPolicy}, abyste zjistili, jak budeme používat vaši zpětnou vazbu.',
    interviewTerms: 'Podmínky rozhovoru',
    privacyPolicy: 'Zásady ochrany osobních údajů',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  da: {
    firstParagraph: 'Før vi starter, bedes du tjekke vores {interviewTerms} og {privacyPolicy} for at se, hvordan vi vil bruge din feedback.',
    interviewTerms: 'Interviewvilkår',
    privacyPolicy: 'Fortrolighedspolitik',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  nl: {
    firstParagraph: 'Voordat we beginnen, bekijk onze {interviewTerms} en {privacyPolicy} om te zien hoe we je feedback zullen gebruiken.',
    interviewTerms: 'Interviewvoorwaarden',
    privacyPolicy: 'Privacybeleid',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  en: {
    firstParagraph: 'Before we get started, please check out our {interviewTerms} and {privacyPolicy} to see how we’ll use your feedback.',
    interviewTerms: 'Interview Terms',
    privacyPolicy: 'Privacy Policy',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  et: {
    firstParagraph: 'Enne kui alustame, palun vaadake üle meie {interviewTerms} ja {privacyPolicy}, et näha, kuidas me teie tagasisidet kasutame.',
    interviewTerms: 'Intervjuu tingimused',
    privacyPolicy: 'Privaatsuspoliitika',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  fi: {
    firstParagraph: 'Ennen kuin aloitamme, tutustu haastatteluehtoihimme {interviewTerms} ja tietosuojakäytäntöömme {privacyPolicy} nähdäksesi, miten käytämme palautettasi.',
    interviewTerms: 'Haastatteluehdot',
    privacyPolicy: 'Tietosuojakäytäntö',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  fr: {
    firstParagraph: 'Avant de commencer, veuillez consulter nos {interviewTerms} et notre {privacyPolicy} pour voir comment nous utiliserons vos retours.',
    interviewTerms: "Conditions d'entretien",
    privacyPolicy: 'Politique de confidentialité',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  gl: {
    firstParagraph: 'Antes de comezar, consulte os nosos {interviewTerms} e {privacyPolicy} para ver como utilizaremos os seus comentarios.',
    interviewTerms: 'Termos da entrevista',
    privacyPolicy: 'Política de privacidade',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  de: {
    firstParagraph: 'Bevor wir beginnen, lesen Sie bitte unsere {interviewTerms} und {privacyPolicy}, um zu erfahren, wie wir Ihr Feedback verwenden.',
    interviewTerms: 'Interviewbedingungen',
    privacyPolicy: 'Datenschutzrichtlinie',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  el: {
    firstParagraph: 'Πριν ξεκινήσουμε, παρακαλούμε να δείτε τους {interviewTerms} και την {privacyPolicy} μας για να δείτε πώς θα χρησιμοποιήσουμε τα σχόλιά σας.',
    interviewTerms: 'Όροι συνέντευξης',
    privacyPolicy: 'Πολιτική απορρήτου',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  he: {
    firstParagraph: 'לפני שנתחיל, אנא עיין ב{interviewTerms} ו{privacyPolicy} שלנו כדי לראות כיצד נשתמש במשוב שלך.',
    interviewTerms: 'תנאי הראיון',
    privacyPolicy: 'מדיניות פרטיות',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hi: {
    firstParagraph: 'शुरू करने से पहले, कृपया हमारे {interviewTerms} और {privacyPolicy} को देखें कि हम आपकी प्रतिक्रिया का उपयोग कैसे करेंगे।',
    interviewTerms: 'साक्षात्कार की शर्तें',
    privacyPolicy: 'गोपनीयता नीति',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  hu: {
    firstParagraph: 'Mielőtt elkezdenénk, kérjük, nézze át a {interviewTerms} és az {privacyPolicy}, hogy lássa, hogyan fogjuk használni a visszajelzését.',
    interviewTerms: 'Interjú feltételei',
    privacyPolicy: 'Adatvédelmi irányelvek',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  is: {
    firstParagraph: 'Áður en við byrjum, vinsamlegast skoðaðu {interviewTerms} okkar og {privacyPolicy} til að sjá hvernig við munum nota álit þitt.',
    interviewTerms: 'Viðtalskjör',
    privacyPolicy: 'Persónuverndarstefna',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  it: {
    firstParagraph: 'Prima di iniziare, ti preghiamo di controllare i nostri {interviewTerms} e {privacyPolicy} per vedere come utilizzeremo il tuo feedback.',
    interviewTerms: 'Termini del colloquio',
    privacyPolicy: 'Politica sulla riservatezza',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ja: {
    firstParagraph: '始める前に、フィードバックの使用方法を確認するために、{interviewTerms} と {privacyPolicy} をご確認ください。',
    interviewTerms: 'インタビューポリシー',
    privacyPolicy: 'プライバシーポリシー',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  kn: {
    firstParagraph: 'ನಾವು ಪ್ರಾರಂಭಿಸುವ ಮೊದಲು, ದಯವಿಟ್ಟು ನಮ್ಮ {interviewTerms} ಮತ್ತು {privacyPolicy} ಅನ್ನು ಪರಿಶೀಲಿಸಿ ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನಾವು ಹೇಗೆ ಬಳಸುತ್ತೇವೆ ಎಂಬುದನ್ನು ನೋಡಲು.',
    interviewTerms: 'ಮೂಲಕ ನಿಯಮಗಳು',
    privacyPolicy: 'ಗೌಪ್ಯತಾ ನೀತಿ',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  kk: {
    firstParagraph: 'Бастамас бұрын, пікіріңізді қалай қолданатынымызды білу үшін біздің {interviewTerms} және {privacyPolicy} тексеріңіз.',
    interviewTerms: 'Сұхбат шарттары',
    privacyPolicy: 'Құпиялылық саясаты',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ko: {
    firstParagraph: '시작하기 전에 {interviewTerms} 및 {privacyPolicy}를 확인하여 피드백이 어떻게 사용되는지 확인하십시오.',
    interviewTerms: '인터뷰 약관',
    privacyPolicy: '개인정보 처리방침',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  mk: {
    firstParagraph: 'Пред да започнеме, ве молиме прегледајте ги нашите {interviewTerms} и {privacyPolicy} за да видите како ќе ја користиме вашата повратна информација.',
    interviewTerms: 'Услови за интервју',
    privacyPolicy: 'Политика за приватност',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ms: {
    firstParagraph: 'Sebelum kita mulakan, sila semak {interviewTerms} dan {privacyPolicy} kami untuk melihat bagaimana kami akan menggunakan maklum balas anda.',
    interviewTerms: 'Terma Wawancara',
    privacyPolicy: 'Dasar Privasi',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  mi: {
    firstParagraph: 'I mua i to tatou tīmatanga, tēnā tirohia ā mātou {interviewTerms} me te {privacyPolicy} kia kite ai koe me pēhea tā mātou whakamahinga i tō urupare.',
    interviewTerms: 'Ngā Ture Uiui',
    privacyPolicy: 'Kaupapahere Whaiaro',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  mr: {
    firstParagraph: 'आम्ही सुरुवात करण्यापूर्वी, कृपया आमचे {interviewTerms} आणि {privacyPolicy} तपासा की आम्ही तुमचा अभिप्राय कसा वापरू.',
    interviewTerms: 'मुलाखत अटी',
    privacyPolicy: 'गोपनीयता धोरण',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  pa: {
    firstParagraph: 'ਅਸੀਂ ਸ਼ੁਰੂ ਕਰਨ ਤੋਂ ਪਹਿਲਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ {interviewTerms} ਅਤੇ {privacyPolicy} ਦੀ ਜਾਂਚ ਕਰੋ ਤਾਂ ਜੋ ਤੁਸੀਂ ਦੇਖ ਸਕੋ ਕਿ ਅਸੀਂ ਤੁਹਾਡੇ ਫੀਡਬੈਕ ਨੂੰ ਕਿਵੇਂ ਵਰਤਾਂਗੇ।',
    interviewTerms: 'ਇੰਟਰਵਿਊ ਦੀਆਂ ਸ਼ਰਤਾਂ',
    privacyPolicy: 'ਪ੍ਰਾਈਵੇਸੀ ਪਾਲਿਸੀ',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  fa: {
    firstParagraph: 'قبل از شروع، لطفاً شرایط {interviewTerms} و {privacyPolicy} را بررسی کنید تا ببینید چگونه از نظرات شما استفاده خواهیم کرد.',
    interviewTerms: 'شرایط مصاحبه',
    privacyPolicy: 'سیاست حفظ حریم خصوصی',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  pl: {
    firstParagraph: 'Zanim zaczniemy, prosimy o zapoznanie się z naszymi {interviewTerms} i {privacyPolicy}, aby zobaczyć, jak będziemy korzystać z Twoich opinii.',
    interviewTerms: 'Warunki wywiadu',
    privacyPolicy: 'Polityka prywatności',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  pt: {
    firstParagraph: 'Antes de começarmos, por favor, consulte os nossos {interviewTerms} e {privacyPolicy} para ver como usaremos o seu feedback.',
    interviewTerms: 'Termos de Entrevista',
    privacyPolicy: 'Política de Privacidade',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ro: {
    firstParagraph: 'Înainte de a începe, te rugăm să consulți {interviewTerms} și {privacyPolicy} pentru a vedea cum vom folosi feedback-ul tău.',
    interviewTerms: 'Termeni de Interviu',
    privacyPolicy: 'Politica de Confidențialitate',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ru: {
    firstParagraph: 'Прежде чем мы начнем, пожалуйста, ознакомьтесь с нашими {interviewTerms} и {privacyPolicy}, чтобы узнать, как мы будем использовать ваши отзывы.',
    interviewTerms: 'Условия интервью',
    privacyPolicy: 'Политика конфиденциальности',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sr: {
    firstParagraph: 'Пре него што почнемо, молимо вас да прегледате наше {interviewTerms} и {privacyPolicy} да бисте видели како ћемо користити ваше повратне информације.',
    interviewTerms: 'Услови интервјуа',
    privacyPolicy: 'Политика приватности',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sk: {
    firstParagraph: 'Predtým, ako začneme, si prosím preštudujte naše {interviewTerms} a {privacyPolicy}, aby ste videli, ako použijeme vašu spätnú väzbu.',
    interviewTerms: 'Podmienky rozhovoru',
    privacyPolicy: 'Zásady ochrany osobných údajov',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sl: {
    firstParagraph: 'Preden začnemo, prosimo, preglejte naše {interviewTerms} in {privacyPolicy}, da vidite, kako bomo uporabili vaše povratne informacije.',
    interviewTerms: 'Pogoji intervjuja',
    privacyPolicy: 'Politika zasebnosti',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  es: {
    firstParagraph: 'Antes de comenzar, por favor revisa nuestros {interviewTerms} y {privacyPolicy} para ver cómo usaremos tus comentarios.',
    interviewTerms: 'Términos de Entrevista',
    privacyPolicy: 'Política de Privacidad',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  sv: {
    firstParagraph: 'Innan vi börjar, vänligen granska våra {interviewTerms} och {privacyPolicy} för att se hur vi kommer att använda din feedback.',
    interviewTerms: 'Intervjuregler',
    privacyPolicy: 'Integritetspolicy',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ta: {
    firstParagraph: 'நாங்கள் தொடங்குவதற்கு முன், உங்கள் கருத்துக்களை எவ்வாறு பயன்படுத்துவோம் என்பதைக் காண, தயவுசெய்து எங்கள் {interviewTerms} மற்றும் {privacyPolicy} ஐ சரிபார்க்கவும்.',
    interviewTerms: 'சர்வே விதிமுறைகள்',
    privacyPolicy: 'தனியுரிமைக் கொள்கை',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  te: {
    firstParagraph: 'మేము ప్రారంభించడానికి ముందు, దయచేసి మా {interviewTerms} మరియు {privacyPolicy} ను చూడండి మీ అభిప్రాయాన్ని ఎలా ఉపయోగిస్తామో చూడండి.',
    interviewTerms: 'ఇంటర్వ్యూ షరతులు',
    privacyPolicy: 'గోప్యతా విధానం',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  th: {
    firstParagraph: 'ก่อนที่เราจะเริ่มต้น กรุณาตรวจสอบ {interviewTerms} และ {privacyPolicy} ของเราเพื่อดูว่าเราจะใช้ข้อเสนอแนะของคุณอย่างไร',
    interviewTerms: 'เงื่อนไขการสัมภาษณ์',
    privacyPolicy: 'นโยบายความเป็นส่วนตัว',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  tr: {
    firstParagraph: 'Başlamadan önce, geri bildiriminizi nasıl kullanacağımızı görmek için lütfen {interviewTerms} ve {privacyPolicy} inceleyin.',
    interviewTerms: 'Röportaj Şartları',
    privacyPolicy: 'Gizlilik Politikası',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  uk: {
    firstParagraph: 'Перш ніж ми почнемо, будь ласка, ознайомтеся з нашими {interviewTerms} та {privacyPolicy}, щоб побачити, як ми будемо використовувати ваші відгуки.',
    interviewTerms: "Умови інтерв'ю",
    privacyPolicy: 'Політика конфіденційності',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  ur: {
    firstParagraph: 'شروع کرنے سے پہلے، براہ کرم ہمارے {interviewTerms} اور {privacyPolicy} کا جائزہ لیں تاکہ یہ دیکھ سکیں کہ ہم آپ کی رائے کو کس طرح استعمال کریں گے۔',
    interviewTerms: 'انٹرویو کی شرائط',
    privacyPolicy: 'رازداری کی پالیسی',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  vi: {
    firstParagraph: 'Trước khi chúng ta bắt đầu, vui lòng xem qua {interviewTerms} và {privacyPolicy} của chúng tôi để xem chúng tôi sẽ sử dụng phản hồi của bạn như thế nào.',
    interviewTerms: 'Điều khoản Phỏng vấn',
    privacyPolicy: 'Chính sách Bảo mật',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  cy: {
    firstParagraph: 'Cyn i ni ddechrau, darllenwch ein {interviewTerms} a {privacyPolicy} i weld sut y byddwn yn defnyddio eich adborth.',
    interviewTerms: 'Telerau Cyfweliad',
    privacyPolicy: 'Polisi Preifatrwydd',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
  yi: {
    firstParagraph: 'איידער מיר אָנהייבן, ביטע קוק איבער אונדזער {interviewTerms} און {privacyPolicy} צו זען ווי מיר וועלן נוצן דיין באַמערקונגען.',
    interviewTerms: 'אינטערוויו תּנאַים',
    privacyPolicy: 'פּריוואַטקייט פּאָליטיק',
    interviewTermsLink: 'https://tellet.ai/interview-terms',
  },
};

// Hi
export const translateHi: Translation = {
  af: 'Hallo',
  ar: 'مرحبا',
  hy: 'Ողջույն',
  az: 'Salam',
  be: 'Прывітанне',
  bs: 'Zdravo',
  bg: 'Здравейте',
  ca: 'Hola',
  zh: '嗨',
  hr: 'Bok',
  cs: 'Ahoj',
  da: 'Hej',
  nl: 'Hoi',
  en: 'Hi',
  et: 'Tere',
  fi: 'Hei',
  fr: 'Bonjour',
  gl: 'Ola',
  de: 'Hallo',
  el: 'Γεια',
  he: 'היי',
  hi: 'नमस्ते',
  hu: 'Helló',
  is: 'Halló',
  it: 'Ciao',
  ja: 'こんにちは',
  kn: 'ಹಲೋ',
  kk: 'Сәлем',
  ko: '안녕하세요',
  mk: 'Здраво',
  ms: 'Hai',
  mi: 'Kia ora',
  mr: 'हॅलो',
  pa: 'ਹੈਲੋ',
  fa: 'سلام',
  pl: 'Cześć',
  pt: 'Oi',
  ro: 'Bună',
  ru: 'Привет',
  sr: 'Ћао',
  sk: 'Ahoj',
  sl: 'Pozdravljeni',
  es: 'Hola',
  sw: 'Hujambo',
  sv: 'Hej',
  ta: 'வணக்கம்',
  th: 'สวัสดี',
  tr: 'Merhaba',
  uk: 'Привіт',
  ur: 'ہیلو',
  vi: 'Xin chào',
  cy: 'Helo',
};

export const translateAreYouReadyToShareYourOpinion: Translation = {
  af: 'Gereed om jou mening met ons te deel?',
  ar: 'هل أنت مستعد لمشاركة رأيك معنا؟',
  hy: 'Պատրաստ եք մեզ հետ ձեր կարծիքը կիսվելու՞:',
  az: 'Rəyinizi bizimlə paylaşmağa hazırsınız?',
  be: 'Гатовы падзяліцца сваім меркаваннем з намі?',
  bs: 'Spreman da podijelite svoje mišljenje s nama?',
  bg: 'Готови ли сте да споделите мнението си с нас?',
  ca: 'Preparat per compartir la teva opinió amb nosaltres?',
  zh: '准备好与我们分享你的意见了吗？',
  hr: 'Spremni za dijeljenje svog mišljenja s nama?',
  cs: 'Jste připraveni sdílet svůj názor s námi?',
  da: 'Klar til at dele din mening med os?',
  nl: 'Klaar om je mening met ons te delen?',
  en: 'Are you ready to share your opinion with us?',
  et: 'Valmis jagama oma arvamust meiega?',
  fi: 'Valmis jakamaan mielipiteesi kanssamme?',
  fr: 'Prêt à partager votre avis avec nous ?',
  gl: 'Preparado para compartir a súa opinión connosco?',
  de: 'Bereit, Ihre Meinung mit uns zu teilen?',
  el: 'Έτοιμος/η να μοιραστείς τη γνώμη σου μαζί μας;',
  he: 'מוכן/ה לשתף אותנו בדעתך?',
  hi: 'क्या आप अपनी राय हमारे साथ साझा करने के लिए तैयार हैं?',
  hu: 'Készen állsz, hogy megoszd velünk a véleményed?',
  is: 'Ertu tilbúinn að deila skoðun þinni með okkur?',
  it: 'Pronto a condividere la tua opinione con noi?',
  ja: '私たちと意見を共有する準備はできていますか？',
  kn: 'ನಿಮ್ಮ ಅಭಿಪ್ರಾಯವನ್ನು ನಮ್ಮೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲು ಸಿದ್ಧವೇ?',
  kk: 'Өз пікіріңізбен бізбен бөлісуге дайынсыз ба?',
  ko: '우리와 의견을 공유할 준비가 되셨나요?',
  mk: 'Подготвени ли сте да ја споделите вашата мисла со нас?',
  ms: 'Sedia untuk berkongsi pendapat anda dengan kami?',
  mi: 'Kua rite koe ki te tātai whakaaro ki a mātou?',
  mr: 'आमच्यासोबत आपले मत सामायिक करण्यासाठी तयार आहात का?',
  pa: 'ਕੀ ਤੁਸੀਂ ਸਾਡੇ ਨਾਲ ਆਪਣੀ ਰਾਏ ਸਾਂਝਾ ਕਰਨ ਲਈ ਤਿਆਰ ਹੋ?',
  fa: 'آماده‌اید نظر خود را با ما به اشتراک بگذارید؟',
  pl: 'Gotowy, aby podzielić się swoją opinią z nami?',
  pt: 'Pronto para compartilhar sua opinião conosco?',
  ro: 'Pregătit să îți împărtășești opinia cu noi?',
  ru: 'Готовы поделиться своим мнением с нами?',
  sr: 'Spreman da podelite svoje mišljenje sa nama?',
  sk: 'Pripravení podeliť sa s nami o svoj názor?',
  sl: 'Pripravljen deliti svoje mnenje z nami?',
  es: '¿Listo para compartir tu opinión con nosotros?',
  sw: 'Tayari kushiriki maoni yako nasi?',
  sv: 'Redo att dela din åsikt med oss?',
  ta: 'உங்கள் கருத்தைப் பகிர்வதற்கு தயார்?',
  th: 'พร้อมที่จะแบ่งปันความคิดเห็นของคุณกับเราหรือยัง?',
  tr: 'Görüşünüzü bizimle paylaşmaya hazır mısınız?',
  uk: 'Готові поділитися своєю думкою з нами?',
  ur: 'کیا آپ اپنی رائے ہمارے ساتھ شیئر کرنے کے لیے تیار ہیں؟',
  vi: 'Sẵn sàng chia sẻ ý kiến của bạn với chúng tôi?',
  cy: 'Barod i rannu eich barn gyda ni?',
};

// Do the interview in your language of choice!
export const translateInterviewLanguageOfChoice: Translation = {
  af: 'Doen die onderhoud in jou gekose taal',
  ar: 'أجرِ المقابلة بلغتك المفضلة',
  hy: 'Կատարիր հարցազրույցը քո ընտրած լեզվով',
  az: 'Seçdiyiniz dilinizdə müsahibəni edin',
  be: "Правядзіце інтэрв'ю на выбранай вамі мове",
  bs: 'Obavite intervju na vašem izabranom jeziku',
  bg: 'Извършете интервюто на избрания от вас език',
  ca: 'Realitzeu la entrevista en el vostre idioma preferit',
  zh: '用您选择的语言进行访谈',
  hr: 'Napravite intervju na svom odabranom jeziku',
  cs: 'Proveďte rozhovor ve vašem vybraném jazyce',
  da: 'Gennemfør interviewet på dit valgte sprog',
  nl: 'Voer het interview uit in uw gekozen taal',
  en: 'Do the interview in your language of choice',
  et: 'Teostage intervjuu oma valitud keeles',
  fi: 'Suorita haastattelu valitsemallasi kielellä',
  fr: "Faites l'entretien dans votre langue de choix",
  gl: 'Realiza a entrevista na túa lingua de elección',
  de: 'Führe das Interview in der von dir gewählten Sprache',
  el: 'Κάντε τη συνέντευξη στη γλώσσα της επιλογής σας',
  he: 'הפעילו את הראיון בשפת הבחירה שלך',
  hi: 'अपनी पसंदीदा भाषा में साक्षात्कार करें',
  hu: 'Végezze el a megbeszélést a választott nyelven',
  is: 'Haldið viðtalið á valinni tungumáli',
  it: "Fai l'intervista nella tua lingua preferita",
  ja: '選択した言語でインタビューを行う',
  kn: 'ನಿಮ್ಮ ಆಯ್ಕೆಯ ಭಾಷೆಯಲ್ಲಿ ಸಾಕ್ಷಾತ್ಕಾರ ನಡೆಸಿ',
  kk: 'Таңдалған тіліңізде интервью өтіңіз',
  ko: '선택한 언어로 인터뷰를 진행하십시오',
  mk: 'Изведете интервју на вашето избрано јазик',
  ms: 'Lakukan temu ramah dalam bahasa pilihan anda',
  mi: 'Whakahaere i te whakawhiti kōrero i roto i to rautaki reo whakarite',
  mr: 'आपल्या पसंतीच्या भाषेत साक्षात्कार करा',
  pa: 'ਆਪਣੀ ਪਸੰਦੀਦਾ ਭਾਸ਼ਾ ਵਿੱਚ ਸਾਕਸ਼ੀਅਤ ਕਰੋ',
  fa: 'مصاحبه را به زبان انتخاب شده خود انجام دهید',
  pl: 'Przeprowadź wywiad w wybranej przez siebie języku',
  pt: 'Faça a entrevista na sua língua de escolha',
  ro: 'Realizați interviul în limba dumneavoastră preferată',
  ru: 'Проведите интервью на выбранном вами языке',
  sr: 'Извршите интервју на вашем изабраном језику',
  sk: 'Vykonať rozhovor vo vašom vybranom jazyku',
  sl: 'Izvedite intervju v vašem izbranem jeziku',
  es: 'Realice la entrevista en su idioma de elección',
  sw: 'Fanya mahojiano kwa lugha yako ya chaguo',
  sv: 'Gör intervjun på ditt valda språk',
  ta: 'உங்கள் தேர்ந்தெடுத்த மொழியில் பேட்டியிடுக',
  th: 'ทำการสัมภาษณ์ในภาษาที่คุณเลือก',
  tr: 'Görüşmeyi tercih ettiğiniz dilde yapın',
  uk: "Проведіть інтерв'ю на обраній вами мові",
  ur: 'اپنی مرضی کی زبان میں انٹرویو کریں',
  vi: 'Tiến hành cuộc phỏng vấn bằng ngôn ngữ bạn chọn',
  cy: 'Cynnal y cyfweliad yn eich dewis iaith',
};

// Let's go
export const translateLetsGo: Translation = {
  af: 'Kom ons begin',
  ar: 'لنبدأ',
  hy: 'Սկսենք',
  az: 'Başlayaq',
  be: 'Пачнем',
  bs: 'Počnimo',
  bg: 'Нека започнеме',
  ca: 'Comencem',
  zh: '让我们开始吧',
  hr: 'Krenimo',
  cs: 'Začněme',
  da: 'Lad os begynde',
  nl: 'Laten we beginnen',
  en: "Let's go",
  et: 'Alustame',
  fi: 'Aloitetaan',
  fr: 'Commençons',
  gl: 'Empecemos',
  de: 'Lass uns anfangen',
  el: 'Ας ξεκινήσουμε',
  he: 'בוא נתחיל',
  hi: 'आइए शुरू करें',
  hu: 'Kezdjük el',
  is: 'Byrjum við',
  it: 'Cominciamo',
  ja: '始めましょう',
  kn: 'ಪ್ರಾರಂಭಿಸೋಣ',
  kk: 'Бастау',
  ko: '시작해봅시다',
  mk: 'Да започнеме',
  ms: 'Mari mula',
  mi: 'Tīmataratanga',
  mr: 'आपल्या आरंभा',
  pa: 'ਸ਼ੁਰੂ ਕਰੀਏ',
  fa: 'بیا شروع کنیم',
  pl: 'Zacznijmy',
  pt: 'Vamos começar',
  ro: 'Să începem',
  ru: 'Давайте начнем',
  sr: 'Да почнемо',
  sk: 'Začnime',
  sl: 'Začnimo',
  es: 'Comencemos',
  sw: 'Tuangalie',
  sv: 'Låt oss börja',
  ta: 'தொடங்கவேண்டும்',
  th: 'เริ่มต้นเถอะ',
  tr: 'Başlayalım',
  uk: 'Давайте почнемо',
  ur: 'شروع کرتے ہیں',
  vi: 'Bắt đầu thôi',
  cy: 'Gwnewch yn dechrau',
};

export const translateInterviewTerms: Translation = {
  af: 'Ek het die onderhoudsvoorwaardes gelees en aanvaar',
  ar: 'لقد قرأت وأوافق على شروط المقابلة',
  hy: 'Ես կարդացել և համաձայնում եմ հարցազրույցի պայմաններին',
  az: 'Müsahibə şərtlərini oxudum və qəbul edirəm',
  be: 'Я прачытаў і прымаю ўмовы інтэрв’ю',
  bs: 'Pročitao sam i prihvaćam uslove intervjua',
  bg: 'Прочетох и приемам условията на интервюто',
  ca: 'He llegit i accepto els termes de l’entrevista',
  zh: '我已阅读并接受面试条款',
  hr: 'Pročitao sam i prihvaćam uvjete razgovora',
  cs: 'Přečetl jsem si a souhlasím s podmínkami pohovoru',
  da: 'Jeg har læst og accepterer interviewbetingelserne',
  nl: 'Ik heb de interviewvoorwaarden gelezen en accepteer ze',
  en: 'I have read and accept the interview terms',
  et: 'Olen intervju tingimustega tutvunud ja nõustun nendega',
  fi: 'Olen lukenut ja hyväksyn haastatteluehdot',
  fr: 'J\'ai lu et j\'accepte les conditions de l\'entretien',
  gl: 'Lin dei lido e acepto os termos da entrevista',
  de: 'Ich habe die Interviewbedingungen gelesen und akzeptiere sie',
  el: 'Έχω διαβάσει και αποδέχομαι τους όρους της συνέντευξης',
  he: 'קראתי ואני מקבל את תנאי הראיון',
  hi: 'मैंने साक्षात्कार की शर्तें पढ़ ली हैं और स्वीकार किया है',
  hu: 'Elolvastam és elfogadom a interjú feltételeit',
  is: 'Ég hef lesið og samþykkt viðtals skilmála',
  it: 'Ho letto e accetto i termini dell\'intervista',
  ja: '私は面接の条件を読み、同意します',
  kn: 'ನಾನು ಸಂದರ್ಶನದ ಶರತ್ತನ್ನು ಓದಿದ್ದೇನೆ ಮತ್ತು ಒಪ್ಪುತ್ತೇನೆ',
  kk: 'Мен сұхбат шарттарын оқып, қабылдадым',
  ko: '나는 인터뷰 조건을 읽고 동의합니다',
  mk: 'Ги прочитав и прифаќам условите за интервју',
  ms: 'Saya telah membaca dan menerima terma temu duga',
  mi: 'Kua pānui au, ā, e whakaae ana au ki ngā tikanga uiui',
  mr: 'मी मुलाखतीच्या अटी वाचल्या आहेत आणि मान्य करतो',
  pa: 'ਮੈਂ ਇੰਟਰਵਿਊ ਦੀਆਂ ਸ਼ਰਤਾਂ ਪੜ੍ਹੀਆਂ ਹਨ ਅਤੇ ਮੰਨ ਲਿਆ ਹੈ',
  fa: 'من شرایط مصاحبه را خوانده و قبول کرده‌ام',
  pl: 'Przeczytałem i akceptuję warunki rozmowy kwalifikacyjnej',
  pt: 'Li e aceito os termos da entrevista',
  ro: 'Am citit și accept termenii interviului',
  ru: 'Я прочитал и принимаю условия интервью',
  sr: 'Pročitao sam i prihvatam uslove razgovora',
  sk: 'Prečítal som si a súhlasím s podmienkami pohovoru',
  sl: 'Prebral sem in sprejemam pogoje intervjuja',
  es: 'He leído y acepto los términos de la entrevista',
  sw: 'Nimesoma na nakubali masharti ya mahojiano',
  sv: 'Jag har läst och accepterar intervjubetingelserna',
  ta: 'நான் நேர்காணல் விதிமுறைகளை படித்தேன் மற்றும் ஏற்கிறேன்',
  th: 'ฉันได้อ่านและยอมรับเงื่อนไขการสัมภาษณ์',
  tr: 'Görüşme şartlarını okudum ve kabul ediyorum',
  uk: 'Я прочитав і приймаю умови інтерв’ю',
  ur: 'میں نے انٹرویو کی شرائط پڑھ لی ہیں اور قبول کیا ہے',
  vi: 'Tôi đã đọc và chấp nhận các điều khoản phỏng vấn',
  cy: 'Rwyf wedi darllen ac yn derbyn telerau’r cyfweliad',
};

import Image from 'chat-ui/src/components/Image/Image';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconLoader2 } from '@tabler/icons-react';
import TelletLogo from '@/assets/tellet-logo-main-white.svg';
import EmpathyLogo from '@/assets/empaty-logo.svg';
import { ECompanyThemeName } from '@/enums/enums';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" className="h-full m-auto w-36 animate-pulse" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" className="h-full m-auto w-52 animate-pulse" />,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FullPageLoader({ themeData }: any) {
  const { themeName } = useTheme();

  return (
    <div
      style={{ backgroundColor: themeData?.['chat-consent-bg'] || '#FFFFFF' }}
      className="fixed inset-0 flex items-center justify-center w-full h-full text-center z-100"
    >
      <div className="flex flex-col items-center justify-center">
        {themeData?.name === 'Default' ? themeLogosList[themeName]
          : (
            <IconLoader2
              className="animate-spin"
              size={40}
            />
          )}
      </div>
    </div>
  );
}

export default FullPageLoader;
